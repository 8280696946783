import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { ThemeProvider } from '@material-tailwind/react'
import { CookiesProvider } from 'react-cookie'
import Script from 'next/script'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import * as gtag from '../services/gtag'

const theme = {
  select: {
    defaultProps: {
      variant: 'standard',
      size: 'lg',
      className: 'text-base'
    },
    styles: {
      base: {
        container: {
          minWidth: 'min-w-[20px]'
        }
      }
    }
  },
  input: {
    defaultProps: {
      variant: 'standard',
      size: 'lg',
      className: 'text-base'
    },
    styles: {
      base: {
        container: {
          minWidth: 'min-w-[20px]'
        }
      }
    }
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => router.events.off("routeChangeComplete", handleRouteChange)
  }, [router.events])
  
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon/favicon.png" />
        <meta name="robots" content="index, follow" />
      </Head>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-NSGEDGGM3Q" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-NSGEDGGM3Q');
      `}
      </Script>
      <ThemeProvider value={theme}>
        <CookiesProvider>
          <Component {...pageProps} />
        </CookiesProvider>
      </ThemeProvider>
    </>
  )
}

export default MyApp
